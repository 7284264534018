import type { Location } from "history";

interface LoginQueryString {
  redirect?: string;
}

interface LoginQueryParams {
  provider?: string;
}

interface LoginProps {
  params?: LoginQueryParams;
  location?: Location<LoginQueryString>;
}

export const Login = ({ params, location }: LoginProps): JSX.Element => {
  document.cookie = "metabase.SESSION=; path=/; max-age=0";
  window.location.replace(
    window.location.href.replace(
      new RegExp("^(https?://)([^.]+).([^/]+)/auth/login\\?redirect=(.*)$"),
      "$1$3/gather/metabase/takemethere?redirect=$4",
    ),
  );
  return <div></div>;
};
