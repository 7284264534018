var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var apa,bpa,cpa,g0,h0,i0,dpa,epa,fpa,k0,l0,gpa;$CLJS.f0=function(a){var b=$CLJS.dd($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.kH($CLJS.H([$CLJS.qH])),"$"].join("")),a));return $CLJS.n(b)?b:a};apa=function(a,b){return a.isSame(b,"day")};bpa=function(a,b){return a.isSame(b,"month")};cpa=function(a,b){return a.isSame(b,"year")};
g0=function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.bj);d=$CLJS.J.g(d,$CLJS.c0);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.LH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null):null):null},$CLJS.$oa)};h0=function(a){return a instanceof Date?$CLJS.LH.utc(a):a};i0=function(a,b,c){b="string"===typeof b?$CLJS.z(g0(b)):h0(b);return("string"===typeof c?$CLJS.z(g0(c)):h0(c)).diff(b,$CLJS.Xg(a))};
dpa=function(a,b){return i0.j?i0.j($CLJS.Kk,a,b):i0.call(null,$CLJS.Kk,a,b)};epa=function(a,b){if("string"===typeof a)return a;a=h0(a);var c=function(){if($CLJS.Dz(b,$CLJS.Gk))return $CLJS.$_;if($CLJS.Dz(b,$CLJS.Lk))return $CLJS.Z_;if($CLJS.Dz(b,$CLJS.zk))return $CLJS.d0;if($CLJS.Dz(b,$CLJS.Ck))return $CLJS.a0;if($CLJS.Dz(b,$CLJS.wk))return $CLJS.b0;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.e0.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,c],null))};
$CLJS.j0=function(a){if($CLJS.n($CLJS.LH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.LH)($CLJS.f0(a),fpa);throw $CLJS.Uh("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.Zi,a],null));};fpa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];k0=function k0(a,b){if("string"===typeof a){var d=g0(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=k0.g?k0.g(a,b):k0.call(null,a,b);return $CLJS.e0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null))}a=h0(a);return a.startOf($CLJS.Xg(b))};
l0=function l0(a,b,c){if("string"===typeof a){var e=g0(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=l0.j?l0.j(a,b,c):l0.call(null,a,b,c);return $CLJS.e0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,e],null))}a=h0(a);return a.add(c,$CLJS.Xg(b))};gpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.m0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.lH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.n0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.Y_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.hpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(apa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ipa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(bpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.jpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(cpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(i0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.o0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(k0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(l0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.p0=function p0(a){switch(arguments.length){case 1:return p0.h(arguments[0]);case 2:return p0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.shared.util.time.coerce_to_timestamp",$CLJS.p0);$CLJS.p0.h=function(a){return $CLJS.p0.g(a,$CLJS.N)};
$CLJS.p0.g=function(a,b){b=$CLJS.ok.l($CLJS.H([$CLJS.N,$CLJS.fA(b)]));if(!$CLJS.n($CLJS.X_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.LH.parseZone(a):"string"===typeof a?$CLJS.mH.g(a,b):$CLJS.nH.g(a,b)}return $CLJS.n(gpa.h(b))?a.local():a};$CLJS.p0.A=2;module.exports={coerce_to_timestamp:$CLJS.p0,coerce_to_time:$CLJS.j0};