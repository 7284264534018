var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var PK,QK,RK,nja,oja,VK,pja,YK,ZK,$K,aL,qja,bL,cL,eL,fL,SK,gL,rja,hL,sja,iL,jL,kL,tja,lL,mL,uja,nL,vja,oL,pL,wja,rL,xja,yja,zja,tL,uL,vL,wL,xL,yL,zL,AL,Aja,BL,CL,DL,FL,GL,Bja,HL,IL,JL,KL,Cja,LL,ML,NL,OL,Dja,PL,QL,RL,SL,Eja,TL,Fja,UL,VL,WL,Gja,XL,YL,ZL,$L,bM,cM,Hja,dM,eM,Ija,fM,gM,hM,iM,jM,Jja,kM,Kja,lM,mM,oM,Lja,pM,qM,rM,Mja,uM,vM,Nja,Oja,wM,yM,zM,AM,Pja,DM,EM,Qja,Rja,Sja,FM,GM,HM,IM,JM,KM,Tja,LM,Uja,MM,NM,PM,RM,SM,TM,UM,Vja,Wja,WM,Xja,Yja,XM,YM,ZM,$M,aN,Zja,bN,$ja,aka,cN,dN,eN,fN,gN,
bka,cka,dka,hN,eka,iN,fka,kN,lN,gka,nN,oN,pN,qN,hka,tN,ika,jka,uN,vN,wN,kka,xN,yN,zN,AN,lka,CN,DN,EN,FN,GN,mka,HN,IN,nka,JN,KN,LN,MN,NN,ON,QN,oka,RN,pka,SN,TN,UN,qka,rka,ska,WN,XN,YN,tka,ZN,$N,aO,bO,uka,vka,dO,wka,xka,eO,fO,gO,hO,iO,yka,jO,kO,lO,mO,zka,nO,oO,pO,qO,sO,Aka,tO,uO,Bka,vO,wO,xO,Cka,yO,Dka,AO,Eka,BO,CO,DO,EO,FO,GO,HO,Fka,Gka,Hka,IO,Ika,JO,KO,Jka,Kka,LO,MO,NO,Lka,OO,Mka,PO,Nka,Oka,QO,Pka,Qka,Rka,TO,UO,Ska,VO,WO,YO,Tka,ZO,Uka,$O,aP,bP,Vka,cP,dP,eP,fP,Wka,hP,iP,Xka,jP,kP,lP,mP,nP,oP,pP,qP,
Yka,rP,sP,Zka,tP,$ka,uP,vP,wP,ala,xP,yP,bla,cla,zP,AP,BP,dla,ela,CP,fla,gla,hla,EP,ila,GP,jla,kla,HP,IP,lla,mla,nla,JP,KP,LP,MP,NP,OP,PP,ola,QP,RP,pla,SP,qla,rla,sla,TP,UP,VP,tla,WP,XP,ula,YP,vla,ZP,wla,xla,aQ,bQ,cQ,dQ,eQ,yla,fQ,gQ,zla,hQ,iQ,jQ,kQ,lQ,mQ,nQ,oQ,Ala,pQ,qQ,sQ,tQ,uQ,vQ,wQ,xQ,yQ,zQ,AQ,BQ,Bla,CQ,DQ,EQ,FQ,GQ,Cla,HQ,IQ,JQ,KQ,Dla,MQ,Ela,Fla,OQ,Gla,PQ,Hla,QQ,Ila,Jla,RQ,Kla,SQ,UQ,VQ,Lla,Mla,ZQ,$Q,Nla,Ola,aR,bR,cR,dR,eR,Pla,fR,gR,iR,jR,Qla;
$CLJS.OK=function(a){var b=$CLJS.Bn.g(a,null),c=$CLJS.lE(b,$CLJS.Br,function(d){return $CLJS.Hm(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Pk,b,$CLJS.Zi,k,$CLJS.Hr,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
PK=function(a,b){return $CLJS.sd(a)?$CLJS.J.g(a,b):a};QK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Km($CLJS.Bn.g($CLJS.Pk.h(a),null));if($CLJS.n(b)){var e=PK($CLJS.Es.h(b),c);$CLJS.n(e)?(e=$CLJS.zp(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:PK($CLJS.ts.h(b),c)}return null};RK=function(a,b){return $CLJS.qd(a)||$CLJS.gl(a)?$CLJS.J.g(a,b):$CLJS.rd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Pk),d=$CLJS.J.g(a,$CLJS.Mi);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Hr,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.Fs),l=$CLJS.J.j(b,$CLJS.Cs,$CLJS.As),m=QK(a,$CLJS.Bp.h(c),k,b);if($CLJS.n(m))return m;m=QK(a,$CLJS.Tr.h(c),k,b);if($CLJS.n(m))return m;m=QK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=QK(a,function(){var t=$CLJS.nn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=QK(a,$CLJS.Bp.h(c),
l,b);if($CLJS.n(m))return m;m=QK(a,$CLJS.Tr.h(c),l,b);if($CLJS.n(m))return m;d=QK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=QK(a,function(){var t=$CLJS.nn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?QK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?QK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Mk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Pk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.Fs);l=$CLJS.J.j(l,$CLJS.Cs,$CLJS.As);k=$CLJS.Bp.h(k);f=PK($CLJS.Bs.h(k),f);f=$CLJS.n(f)?f:PK($CLJS.Bs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.TK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Zi);a=$CLJS.J.g(b,$CLJS.Hr);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.ui),f=$CLJS.J.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.ui,m);l=e.h?e.h(l):e.call(null,l);return SK(k,c,t,l)},null,a):null};
VK=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.YC),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.YC,$CLJS.UK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.WK=function(a){return VK($CLJS.Ej,a,function(){return $CLJS.mE.h(a)})};pja=function(){var a=XK;return VK($CLJS.Br,a,function(){var b=$CLJS.mE.h(a),c=$CLJS.OK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
YK=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};ZK=function(a){if($CLJS.ud(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,YK(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,YK(c)],null);default:return YK(a)}}else return YK(a)};
$K=function(a,b){var c=$CLJS.ud(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
aL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe($K,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,ZK(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,ZK(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Wr(2,2,b))}())],null)};qja=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
bL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.si,qja,$CLJS.ts,["valid instance of one of these MBQL clauses: ",$CLJS.hs(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
cL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NK],null)],null)};$CLJS.dL={};eL={};fL={};$CLJS.UK=function UK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=UK.j?UK.j(k,f,c):UK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
SK=function SK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=RK(b,c);f=$CLJS.n(a)?a:$CLJS.rd(b)?$CLJS.xf:$CLJS.td(b)?$CLJS.N:$CLJS.fd(b);return $CLJS.n($CLJS.n(c)?$CLJS.zs.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=RK(f,c),d=SK.v?SK.v(b,l,k,d):SK.call(null,b,l,k,d),$CLJS.ll(c)&&$CLJS.rd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.yd(f)?b:$CLJS.Wf.g($CLJS.fd(f),b)),null==f||$CLJS.gl(f)?$CLJS.R.j(f,c,d):$CLJS.qd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.V,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.sd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),SK.v?SK.v(f,b,c,d):SK.call(null,f,b,c,d)):$CLJS.n($CLJS.zs.h($CLJS.ld(f)))?$CLJS.Yd.g(f,d):$CLJS.ud($CLJS.Be(f))?f:$CLJS.kd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.zs,!0],null))};gL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
hL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);iL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);jL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);kL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);lL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);mL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);nL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);oL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);pL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.qL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);rL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.sL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);tL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
uL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);vL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);wL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);xL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);yL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
zL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);AL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);BL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);CL=new $CLJS.r(null,"stddev","stddev",775056588,null);DL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.EL=new $CLJS.M(null,"snippet","snippet",953581994);FL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);GL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);HL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);IL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
JL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);KL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);LL=new $CLJS.M("location","country","location/country",1666636202);ML=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
NL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);OL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);PL=new $CLJS.M(null,"unary","unary",-989314568);QL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
RL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);SL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);TL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);UL=new $CLJS.r(null,"count-where","count-where",2025939247,null);VL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
WL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);XL=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);YL=new $CLJS.r(null,"sum","sum",1777518341,null);ZL=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);$L=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.aM=new $CLJS.M("date","range","date/range",1647265776);bM=new $CLJS.r(null,"between","between",-1523336493,null);cM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);dM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);eM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
fM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);gM=new $CLJS.r(null,"not-null","not-null",313812992,null);hM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);iM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);jM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
kM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);lM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);mM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.nM=new $CLJS.M(null,"context","context",-830191113);oM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);pM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);qM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);rM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.sM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.tM=new $CLJS.M(null,"card","card",-1430355152);uM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);vM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);wM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.xM=new $CLJS.M("date","month-year","date/month-year",1948031290);
yM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);zM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);AM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.BM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.CM=new $CLJS.M(null,"source-field","source-field",933829534);DM=new $CLJS.r(null,"Field","Field",430385967,null);
EM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);FM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);GM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
HM=new $CLJS.M(null,"more","more",-2058821800);IM=new $CLJS.M(null,"first-clause","first-clause",-20953491);JM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);KM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);LM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);MM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);NM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.OM=new $CLJS.M(null,"widget-type","widget-type",1836256899);PM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.QM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
RM=new $CLJS.r(null,"is-null","is-null",-356519403,null);SM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);TM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);UM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.VM=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
WM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);XM=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);YM=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);ZM=new $CLJS.M("string","contains","string/contains",1602423827);$M=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
aN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);bN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
cN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);dN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);eN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);fN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);gN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);hN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
iN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.jN=new $CLJS.M(null,"collection","collection",-683361892);kN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);lN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.mN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);nN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);oN=new $CLJS.r(null,"metric","metric",2049329604,null);pN=new $CLJS.r(null,"concat","concat",-467652465,null);qN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.rN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.sN=new $CLJS.M("date","relative","date/relative",25987732);tN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);uN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);vN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
wN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);xN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);yN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);zN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);AN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.BN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);CN=new $CLJS.r(null,"*","*",345799209,null);DN=new $CLJS.r(null,"+","+",-740910886,null);EN=new $CLJS.r(null,"-","-",-471816912,null);FN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);GN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);HN=new $CLJS.r(null,"asc","asc",1997386096,null);
IN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);JN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);KN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);LN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);MN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);NN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
ON=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.PN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);QN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);RN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
SN=new $CLJS.r(null,"and","and",668631710,null);TN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);UN=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.VN=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);WN=new $CLJS.r(null,"exp","exp",1378825265,null);
XN=new $CLJS.r(null,"Filter","Filter",-424893332,null);YN=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);ZN=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);$N=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);aO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
bO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.cO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);dO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);eO=new $CLJS.M(null,"requires-features","requires-features",-101116256);fO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);gO=new $CLJS.M(null,"clause-name","clause-name",-996419059);hO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);iO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);jO=new $CLJS.r(null,"not","not",1044554643,null);kO=new $CLJS.r(null,"avg","avg",1837937727,null);lO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);mO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);nO=new $CLJS.r(null,"case","case",-1510733573,null);
oO=new $CLJS.r(null,"distinct","distinct",-148347594,null);pO=new $CLJS.r(null,"get-second","get-second",-425414791,null);qO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.rO=new $CLJS.M(null,"join-alias","join-alias",1454206794);sO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
tO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);uO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);vO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);wO=new $CLJS.r(null,"abs","abs",1394505050,null);xO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
yO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.zO=new $CLJS.M(null,"date","date",-1463434462);AO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);BO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
CO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);DO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);EO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);FO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);GO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);HO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);IO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);JO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
KO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);LO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);MO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
NO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);OO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);PO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);QO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.RO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.SO=new $CLJS.M(null,"database","database",1849087575);TO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);UO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);VO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
WO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.XO=new $CLJS.M(null,"expressions","expressions",255689909);YO=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);ZO=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$O=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);aP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);bP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
cP=new $CLJS.r(null,"get-day","get-day",1768100384,null);dP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);eP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);fP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.gP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);hP=new $CLJS.M(null,"page","page",849072397);
iP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);jP=new $CLJS.r(null,"get-week","get-week",752472178,null);kP=new $CLJS.r(null,"get-month","get-month",1271156796,null);lP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);mP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);nP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
oP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);pP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);qP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);rP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);sP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);tP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);uP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);vP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
wP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);xP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);yP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);zP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);AP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);BP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);CP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.DP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);EP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.FP=new $CLJS.M(null,"source-query","source-query",198004422);GP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);HP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);IP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);JP=new $CLJS.M(null,"amount","amount",364489504);KP=new $CLJS.r(null,"percentile","percentile",1039342775,null);LP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);MP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);NP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
OP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);PP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);QP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);RP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
SP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);TP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);UP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
VP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);WP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);XP=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);YP=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);ZP=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.$P=new $CLJS.M(null,"card-id","card-id",-1770060179);aQ=new $CLJS.M(null,"variadic","variadic",882626057);
bQ=new $CLJS.r(null,"upper","upper",1886775433,null);cQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);dQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);eQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);fQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
gQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);hQ=new $CLJS.r(null,"power","power",702679448,null);iQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);jQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);kQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
lQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);mQ=new $CLJS.r(null,"median","median",-2084869638,null);nQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);oQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);pQ=new $CLJS.M(null,"y","y",-1757859776);qQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.rQ=new $CLJS.M(null,"binning","binning",1709835866);sQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);tQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);uQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);vQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);wQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);xQ=new $CLJS.M(null,"b","b",1482224470);
yQ=new $CLJS.M(null,"a","a",-2123407586);zQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);AQ=new $CLJS.r(null,"replace","replace",853943757,null);BQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);CQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
DQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);EQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);FQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);GQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);HQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
IQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);JQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);KQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.LQ=new $CLJS.M(null,"order-by","order-by",1527318070);MQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.NQ=new $CLJS.M(null,"condition","condition",1668437652);OQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);PQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);QQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);RQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
SQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.TQ=new $CLJS.M(null,"database_type","database_type",-54700895);UQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);VQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.WQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.XQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.YQ=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);ZQ=new $CLJS.M("location","state","location/state",-114378652);$Q=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
aR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);bR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);cR=new $CLJS.M(null,"numeric","numeric",-1495594714);dR=new $CLJS.r(null,"variable","variable",1359185035,null);eR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
fR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);gR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.hR=new $CLJS.M(null,"limit","limit",-1355822363);iR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);jR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var kR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qD],null),lR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zD],null),mR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mD],null),nR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iD],null),oR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null),pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.GH],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KH],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BH],null),tR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.ai,null,$CLJS.Kk,null,$CLJS.Oi,null,$CLJS.Oh,null,$CLJS.yk,null,$CLJS.Ui,null,$CLJS.Ik,null,$CLJS.Fk,null,$CLJS.tk,null,$CLJS.Dk,null,$CLJS.uk,null,$CLJS.xk,null],null),null),uR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Jk,null,$CLJS.SF,null,$CLJS.Oh,null,$CLJS.vk,null,$CLJS.VF,null,$CLJS.Bk,null,$CLJS.Ak,null],null),null),
vR=$CLJS.Ws.g(tR,uR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"date bucketing unit"],null)],null),tR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"time bucketing unit"],null)],null),uR),wR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"datetime bucketing unit"],null)],null),vR),xR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,
1,[$CLJS.ts,"temporal extract unit"],null),$CLJS.WF,$CLJS.xk,$CLJS.Ik,$CLJS.WH,$CLJS.jI,$CLJS.kI,$CLJS.Fk,$CLJS.yk,$CLJS.vk,$CLJS.Bk,$CLJS.TF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"datetime-diff unit"],null),$CLJS.SF,$CLJS.Ak,$CLJS.Jk,$CLJS.Kk,$CLJS.Oi,$CLJS.Ui,$CLJS.ai,$CLJS.tk],null),yR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"temporal-extract week extraction mode"],null),$CLJS.QH,$CLJS.XH,$CLJS.aI],null),zR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Ak,$CLJS.Jk,$CLJS.Kk,$CLJS.Oi,$CLJS.Ui,$CLJS.ai,$CLJS.tk],null),GR,Yla,OR,PR,QR,RR,SR,TR,UR,HT,$la,IT,ama,bma,JT,cma,dma,ema;$CLJS.Y(RL,aL($CLJS.ZA,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Qu],null),$CLJS.pr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,zR],null)])));var AR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RL],null);
$CLJS.Y(vO,aL($CLJS.YA,$CLJS.H(["n",$CLJS.pr,"unit",zR])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);
$CLJS.Y(lM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid :absolute-datetime clause",$CLJS.si,function(a){if($CLJS.Ra($K($CLJS.UH,a)))a=kM;else{a=$CLJS.dd(a);var b=$CLJS.WK(qR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.zO:$CLJS.YH}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[kM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zO,aL($CLJS.UH,
$CLJS.H(["date",qR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,aL($CLJS.UH,$CLJS.H(["datetime",rR,"unit",wR]))],null)],null));var BR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);$CLJS.Y(AM,aL($CLJS.Fx,$CLJS.H(["time",sR,"unit",Ula])));var CR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null),DR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"date or datetime literal"],null),BR,rR,qR],null);
$CLJS.Y(lL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"time literal"],null),CR,sR],null));$CLJS.Y(aR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"temporal literal"],null),DR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lL],null)],null));var ER=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aR],null);
$CLJS.Y(pla,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof BR?new $CLJS.Cc(function(){return BR},$CLJS.kd(Qja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,tP,$CLJS.Ni,$CLJS.Gj,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bP,"metabase/mbql/schema.cljc",69,$CLJS.UH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(BR)?BR.H:null])):null));return $CLJS.n(a)?a:bP}(),BR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof AR?new $CLJS.Cc(function(){return AR},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,
$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",29,$CLJS.ZA,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZA,$CLJS.zj,$CLJS.V($CLJS.hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Qu],null),$CLJS.pr],null)),$CLJS.bi,$CLJS.V($CLJS.hj,$CLJS.V(eQ,RN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(AR)?AR.H:null])):null));return $CLJS.n(a)?a:tO}(),AR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof CR?new $CLJS.Cc(function(){return CR},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,tP,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.V($CLJS.hj,ola),$CLJS.bi,$CLJS.V($CLJS.hj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(CR)?CR.H:null])):null));return $CLJS.n(a)?a:SQ}(),CR],null)])));
$CLJS.Y(QQ,aL($CLJS.Zi,$CLJS.H(["value",$CLJS.ur,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,kR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bC,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.cr,mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,wR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,kR],null)],null)],null)],null)])));var FR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.Y(AN,aL($CLJS.qA,$CLJS.H(["expression-name",kR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.sj],null)])));
GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.BD);c=$CLJS.J.g(c,$CLJS.jD);return $CLJS.E.g(b,$CLJS.jD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.BD);c=$CLJS.J.g(c,$CLJS.AD);return $CLJS.E.g(b,$CLJS.AD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"binning strategy"],null),$CLJS.jD,$CLJS.AD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),nR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.AD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.Zk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.tl)],null)],null)],null)],null)));$CLJS.HR=function HR(a){switch(arguments.length){case 1:return HR.h(arguments[0]);case 2:return HR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.HR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.pF);return $CLJS.HR.g(a,b)};$CLJS.HR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.wk)?tR:$CLJS.Dz(a,$CLJS.Lk)?uR:$CLJS.Dz(a,$CLJS.Ck)?vR:null:null;return $CLJS.n(a)?$CLJS.Cd(a,b):!0};$CLJS.HR.A=2;
$CLJS.Y(fQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,wR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,kR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.HR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.BD)],null)],null));
$CLJS.Y(HQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(aL($CLJS.jF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,pR,kR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null)],null)]))));var IR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);
$CLJS.Y(IO,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},$CLJS.kd(MM,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.XQ,$CLJS.V($CLJS.hj,TL),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,$CLJS.sj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:wM}(),GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof IR?new $CLJS.Cc(function(){return IR},
$CLJS.kd(dN,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.cq,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,$CLJS.ci,$CLJS.uj,$CLJS.wj],["0.39.0",$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eM,"metabase/mbql/schema.cljc",51,$CLJS.jF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:eM}(),IR],null)])));
$CLJS.JR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IO],null);$CLJS.Y(gL,aL($CLJS.ZE,$CLJS.H(["aggregation-clause-index",$CLJS.pr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.sj],null)])));var KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gL],null);
$CLJS.Y(NL,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof KR?new $CLJS.Cc(function(){return KR},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qQ,"metabase/mbql/schema.cljc",23,$CLJS.ZE,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZE,Nla,$CLJS.V($CLJS.hj,$CLJS.pr),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,$CLJS.sj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(KR)?KR.H:null])):null));return $CLJS.n(a)?a:qQ}(),KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},
$CLJS.kd(MM,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.XQ,$CLJS.V($CLJS.hj,TL),
$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,$CLJS.sj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:wM}(),GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof IR?new $CLJS.Cc(function(){return IR},$CLJS.kd(dN,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.cq,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,
$CLJS.ci,$CLJS.uj,$CLJS.wj],["0.39.0",$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eM,"metabase/mbql/schema.cljc",51,$CLJS.jF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:eM}(),IR],null)])));
var LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NL],null),MR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.js,null,$CLJS.AF,null,$CLJS.BE,null,$CLJS.GF,null,$CLJS.IE,null,$CLJS.mF,null,$CLJS.SE,null,$CLJS.ps,null,$CLJS.yF,null,$CLJS.lF,null,$CLJS.HF,null],null),null),NR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KL],null);
$CLJS.Y(nL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.si,function(a){return"string"===typeof a?$CLJS.nj:$CLJS.n($K(MR,a))?GL:$CLJS.n($K($CLJS.Zi,a))?$CLJS.Zi:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[GL,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.JR],null)],null));OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nL],null);
PR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.EE,null,$CLJS.iI,null,$CLJS.Jq,null,$CLJS.bB,null,$CLJS.dI,null,$CLJS.XE,null,$CLJS.Zr,null,$CLJS.AE,null,$CLJS.gI,null,$CLJS.$A,null,$CLJS.mF,null,$CLJS.DF,null,$CLJS.eI,null,$CLJS.oF,null,$CLJS.GE,null,$CLJS.SH,null,$CLJS.Iq,null,$CLJS.JE,null,$CLJS.SE,null,$CLJS.MH,null,$CLJS.Pw,null,$CLJS.TH,null,$CLJS.bI,null,$CLJS.XA,null,$CLJS.FF,null,$CLJS.hF,null],null),null);
QR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Gq,null,$CLJS.Aq,null,$CLJS.dr,null,$CLJS.fr,null,$CLJS.Cq,null,$CLJS.zF,null,$CLJS.kr,null,$CLJS.Aj,null,$CLJS.Eq,null],null),null);RR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.ni,null,$CLJS.xF,null,$CLJS.sF,null,$CLJS.kF,null,$CLJS.CF,null,$CLJS.EF,null,$CLJS.OE,null,$CLJS.VE,null,$CLJS.sL,null,$CLJS.PE,null,$CLJS.wF,null,$CLJS.qF,null,$CLJS.tj,null,$CLJS.Rw,null,$CLJS.dF,null,$CLJS.eF,null,$CLJS.vF,null],null),null);
SR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.ZH,null,$CLJS.OH,null,$CLJS.dB,null,$CLJS.Iq,null,$CLJS.aB,null],null),null);TR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YP],null);UR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null);$CLJS.VR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null);$CLJS.WR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);
$CLJS.Y(kQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"numeric expression argument",$CLJS.si,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.n($K(PR,a))?bO:$CLJS.n($K(RR,a))?$CLJS.ZE:$CLJS.n($K($CLJS.Zi,a))?$CLJS.Zi:$CLJS.jF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.Zk],null),new $CLJS.P(null,2,5,$CLJS.Q,[bO,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jF,
$CLJS.JR],null)],null));var YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);
$CLJS.Y(uP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"datetime expression argument",$CLJS.si,function(a){return $CLJS.n($K(RR,a))?$CLJS.ZE:$CLJS.n($K($CLJS.Zi,a))?$CLJS.Zi:$CLJS.n($K(SR,a))?FM:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[FM,$CLJS.VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,DR,$CLJS.JR],null)],null)],null));
var ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);
$CLJS.Y(YO,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"expression argument",$CLJS.si,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.xd(a)?$CLJS.tr:$CLJS.n($K(QR,a))?mP:$CLJS.n($K(PR,a))?bO:$CLJS.n($K(SR,a))?FM:"string"===typeof a?$CLJS.nj:$CLJS.n($K(MR,a))?GL:$CLJS.n($K($CLJS.Zi,a))?$CLJS.Zi:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.Zk],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[mP,UR],null),new $CLJS.P(null,
2,5,$CLJS.Q,[bO,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[FM,$CLJS.VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[GL,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.JR],null)],null));var $R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null);$CLJS.Y(WO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"numeric expression arg or interval"],null),Xla,YR],null));
var aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WO],null);$CLJS.Y(yL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"int greater than zero or numeric expression",$CLJS.si,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,nR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,TR],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yL],null);
$CLJS.Y(GM,aL($CLJS.mF,$CLJS.H(["a",$R,"b",$R,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,$R],null)])));var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);$CLJS.Y(kL,aL($CLJS.BE,$CLJS.H(["s",OR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,YR],null)])));var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kL],null);$CLJS.Y(nP,aL($CLJS.Pw,$CLJS.H(["s",OR])));var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nP],null);$CLJS.Y(ML,aL($CLJS.yF,$CLJS.H(["s",OR])));
var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ML],null);$CLJS.Y(QL,aL($CLJS.HF,$CLJS.H(["s",OR])));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QL],null);$CLJS.Y(iM,aL($CLJS.AF,$CLJS.H(["s",OR])));var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iM],null);$CLJS.Y(UM,aL($CLJS.js,$CLJS.H(["s",OR])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);$CLJS.Y(DL,aL($CLJS.ps,$CLJS.H(["s",OR])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DL],null);
$CLJS.Y(jQ,aL($CLJS.GF,$CLJS.H(["s",OR,"match",$CLJS.nj,"replacement",$CLJS.nj])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);$CLJS.Y(fR,aL($CLJS.IE,$CLJS.H(["a",OR,"b",OR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,OR],null)])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null);$CLJS.Y(JN,aL($CLJS.lF,$CLJS.H(["s",OR,"pattern",$CLJS.nj])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JN],null);
$CLJS.Y(FQ,aL($CLJS.Iq,$CLJS.H(["x",aS,"y",aS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,aS],null)])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);$CLJS.Y(DQ,aL($CLJS.Zr,$CLJS.H(["x",YR,"y",aS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,aS],null)])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);$CLJS.Y(CQ,aL($CLJS.DF,$CLJS.H(["x",YR,"y",YR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,YR],null)])));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.Y(EQ,aL($CLJS.Jq,$CLJS.H(["x",YR,"y",YR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,YR],null)])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.Y(VO,aL($CLJS.hF,$CLJS.H(["x",YR])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VO],null);$CLJS.Y(NP,aL($CLJS.EE,$CLJS.H(["x",YR])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.Y(iR,aL($CLJS.oF,$CLJS.H(["x",YR])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iR],null);$CLJS.Y($N,aL($CLJS.JE,$CLJS.H(["x",YR])));
var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$N],null);$CLJS.Y(fO,aL($CLJS.GE,$CLJS.H(["x",YR,"y",YR])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);$CLJS.Y(CO,aL($CLJS.XE,$CLJS.H(["x",YR])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CO],null);$CLJS.Y(zL,aL($CLJS.AE,$CLJS.H(["x",YR])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zL],null);$CLJS.Y(aP,aL($CLJS.FF,$CLJS.H(["x",YR])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aP],null);
$CLJS.Y(ZL,aL($CLJS.XA,$CLJS.H(["datetime-x",ZR,"datetime-y",ZR,"unit",Wla])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZL],null);$CLJS.Y(qM,aL($CLJS.bB,$CLJS.H(["datetime",ZR,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,yR],null)])));var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);$CLJS.Y(hO,aL($CLJS.TH,$CLJS.H(["date",ZR])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.Y(JO,aL($CLJS.iI,$CLJS.H(["date",ZR])));
var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);$CLJS.Y(mL,aL($CLJS.MH,$CLJS.H(["date",ZR])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mL],null);$CLJS.Y(gR,aL($CLJS.$A,$CLJS.H(["date",ZR,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,yR],null)])));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gR],null);$CLJS.Y(uN,aL($CLJS.bI,$CLJS.H(["date",ZR])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.Y(DO,aL($CLJS.eI,$CLJS.H(["date",ZR])));
var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DO],null);$CLJS.Y(VL,aL($CLJS.gI,$CLJS.H(["datetime",ZR])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VL],null);$CLJS.Y(zN,aL($CLJS.dI,$CLJS.H(["datetime",ZR])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);$CLJS.Y(OL,aL($CLJS.SH,$CLJS.H(["datetime",ZR])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OL],null);$CLJS.Y(eN,aL($CLJS.OH,$CLJS.H(["datetime",ZR,"to",xR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,xR],null)])));
var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null),LS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"datetime arithmetic unit"],null),$CLJS.VF,$CLJS.SF,$CLJS.Ak,$CLJS.Jk,$CLJS.Kk,$CLJS.Oi,$CLJS.Ui,$CLJS.ai,$CLJS.tk],null);$CLJS.Y(jL,aL($CLJS.aB,$CLJS.H(["datetime",ZR,"amount",YR,"unit",LS])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jL],null);
$CLJS.Y(HL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return aL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.ZH));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HL],null);$CLJS.Y(iL,aL($CLJS.dB,$CLJS.H(["datetime",ZR,"amount",YR,"unit",LS])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iL],null);
$CLJS.Y(zP,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",51,$CLJS.Iq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Iq,$CLJS.yD,$CLJS.V($CLJS.hj,TO),pQ,$CLJS.V($CLJS.hj,TO),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,TO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:DN}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},
$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[YM,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",67,$CLJS.aB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aB,$CLJS.YH,$CLJS.V($CLJS.hj,bR),JP,$CLJS.V($CLJS.hj,
OP),$CLJS.bi,$CLJS.V($CLJS.hj,WP)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:KO}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.kd(rja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,
$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[YM,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",72,$CLJS.dB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dB,$CLJS.YH,$CLJS.V($CLJS.hj,bR),JP,$CLJS.V($CLJS.hj,OP),$CLJS.bi,$CLJS.V($CLJS.hj,WP)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(OS)?OS.H:null])):null));
return $CLJS.n(a)?a:$Q}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.kd(vla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.OH,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EM,"metabase/mbql/schema.cljc",71,$CLJS.OH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OH,$CLJS.YH,$CLJS.V($CLJS.hj,bR),qka,$CLJS.V($CLJS.hj,sQ),vja,$CLJS.V($CLJS.hj,$CLJS.V(eQ,sQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:EM}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&
"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof NS?new $CLJS.Cc(function(){return NS},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZH,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iO,"metabase/mbql/schema.cljc",
45,$CLJS.ZH,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZH],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:iO}(),NS],null)])));$CLJS.PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FO],null);$CLJS.Y(oL,aL($CLJS.kr,$CLJS.H(["first-clause",$CLJS.PS,"second-clause",$CLJS.PS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,$CLJS.PS],null)])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oL],null);
$CLJS.Y(aN,aL($CLJS.dr,$CLJS.H(["first-clause",$CLJS.PS,"second-clause",$CLJS.PS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,$CLJS.PS],null)])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null);$CLJS.Y(JL,aL($CLJS.fr,$CLJS.H(["clause",$CLJS.PS])));
var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JL],null),TS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,3,[$CLJS.ts,":field or :expression reference or :relative-datetime",$CLJS.Es,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.si,function(a){return $CLJS.n($K($CLJS.ZA,a))?$CLJS.ZA:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZA,AR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.JR],null)],null);
$CLJS.Y(wP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.ts,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr,$CLJS.Zk,$CLJS.nj,ER,TS,$R,FR],null)],null));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wP],null);
$CLJS.Y(ON,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"order comparable",$CLJS.si,function(a){return $CLJS.n($K($CLJS.Zi,a))?$CLJS.Zi:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,FR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dr,$CLJS.Zk,$CLJS.nj,ER,$R,TS],null)],null)],null));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ON],null);
$CLJS.Y(vQ,aL($CLJS.Aj,$CLJS.H(["field",US,"value-or-field",US,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,US],null)])));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);$CLJS.Y(zQ,aL($CLJS.zF,$CLJS.H(["field",US,"value-or-field",US,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,US],null)])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);$CLJS.Y(uQ,aL($CLJS.Eq,$CLJS.H(["field",VS,"value-or-field",VS])));
var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uQ],null);$CLJS.Y(tQ,aL($CLJS.Aq,$CLJS.H(["field",VS,"value-or-field",VS])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.Y(jM,aL($CLJS.Gq,$CLJS.H(["field",VS,"value-or-field",VS])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jM],null);$CLJS.Y(pM,aL($CLJS.Cq,$CLJS.H(["field",VS,"value-or-field",VS])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);$CLJS.Y(eP,aL($CLJS.LE,$CLJS.H(["field",VS,"min",VS,"max",VS])));
var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eP],null);$CLJS.Y(GP,aL($CLJS.RE,$CLJS.H(["lat-field",VS,"lon-field",VS,"lat-max",VS,"lon-min",VS,"lat-min",VS,"lon-max",VS])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);$CLJS.Y(TM,aL($CLJS.KE,$CLJS.H(["field",$CLJS.JR])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.Y(IN,aL($CLJS.WE,$CLJS.H(["field",$CLJS.JR])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IN],null);$CLJS.Y(kN,aL($CLJS.ME,$CLJS.H(["field",$CLJS.JR])));
var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.Y(rL,aL($CLJS.TE,$CLJS.H(["field",$CLJS.JR])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rL],null),hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null);$CLJS.Y(xN,aL($CLJS.aF,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,hT],null)])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);
$CLJS.Y(qO,aL($CLJS.uF,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,hT],null)])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null);$CLJS.Y(iN,aL($CLJS.iF,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,hT],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null);$CLJS.Y(ZO,aL($CLJS.bF,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,hT],null)])));
var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZO],null);$CLJS.Y(AL,aL($CLJS.cB,$CLJS.H(["field",$CLJS.JR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null)],null),"unit",zR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AL],null);
$CLJS.Y(pP,aL($CLJS.HE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null),kR],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);
$CLJS.Y(yM,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.kd(iQ,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SN,"metabase/mbql/schema.cljc",15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,IM,$CLJS.V($CLJS.hj,XN),AO,$CLJS.V($CLJS.hj,XN),SM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,XN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:SN}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},
$CLJS.kd(EO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",14,$CLJS.dr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,IM,$CLJS.V($CLJS.hj,XN),AO,$CLJS.V($CLJS.hj,XN),SM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,XN))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:HO}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.kd(qP,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",15,$CLJS.fr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.WQ,$CLJS.V($CLJS.hj,XN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:jO}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==
typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",13,$CLJS.Eq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Eq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:LN}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(PQ,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KN,"metabase/mbql/schema.cljc",14,$CLJS.Gq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:KN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&
"undefined"!==typeof fL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",13,$CLJS.Aq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aq,$CLJS.jF,$CLJS.V($CLJS.hj,
KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:MN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(IQ,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],
[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",14,$CLJS.Cq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:TN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&
"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.kd(MO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",13,$CLJS.Aj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Aj,$CLJS.jF,$CLJS.V($CLJS.hj,UQ),UP,$CLJS.V($CLJS.hj,UQ),xP,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,UQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:NN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.kd($L,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,
$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vM,"metabase/mbql/schema.cljc",14,$CLJS.zF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zF,$CLJS.jF,$CLJS.V($CLJS.hj,UQ),UP,$CLJS.V($CLJS.hj,UQ),xP,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,UQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(XS)?XS.H:null])):null));
return $CLJS.n(a)?a:vM}(),XS],null)])));
$CLJS.Y(FO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid filter expression",$CLJS.si,function(a){return $CLJS.n($K(SR,a))?$CLJS.YH:$CLJS.n($K(PR,a))?cR:$CLJS.n($K(MR,a))?$CLJS.nj:$CLJS.n($K(QR,a))?$CLJS.tr:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,$CLJS.VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[cR,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,bL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.kd(iQ,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",
15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,IM,$CLJS.V($CLJS.hj,XN),AO,$CLJS.V($CLJS.hj,XN),SM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,XN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:SN}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},$CLJS.kd(EO,new $CLJS.h(null,1,[$CLJS.oj,
!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",14,$CLJS.dr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,IM,$CLJS.V($CLJS.hj,XN),AO,$CLJS.V($CLJS.hj,XN),SM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,XN))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(RS)?
RS.H:null])):null));return $CLJS.n(a)?a:HO}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.kd(qP,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jO,"metabase/mbql/schema.cljc",15,$CLJS.fr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.WQ,$CLJS.V($CLJS.hj,XN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:jO}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.kd(MO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,
$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",13,$CLJS.Aj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Aj,$CLJS.jF,$CLJS.V($CLJS.hj,UQ),UP,$CLJS.V($CLJS.hj,UQ),xP,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,UQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(WS)?WS.H:null])):
null));return $CLJS.n(a)?a:NN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.kd($L,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vM,"metabase/mbql/schema.cljc",14,$CLJS.zF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zF,$CLJS.jF,$CLJS.V($CLJS.hj,UQ),UP,$CLJS.V($CLJS.hj,UQ),xP,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,UQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:vM}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},
$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",13,$CLJS.Eq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Eq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:LN}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MN,"metabase/mbql/schema.cljc",13,$CLJS.Aq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:MN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(PQ,new $CLJS.h(null,
1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KN,"metabase/mbql/schema.cljc",14,$CLJS.Gq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n($S)?$S.H:null])):
null));return $CLJS.n(a)?a:KN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(IQ,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TN,"metabase/mbql/schema.cljc",14,$CLJS.Cq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cq,$CLJS.jF,$CLJS.V($CLJS.hj,KM),UP,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:TN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(Qka,new $CLJS.h(null,
1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bM,"metabase/mbql/schema.cljc",19,$CLJS.LE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LE,$CLJS.jF,$CLJS.V($CLJS.hj,KM),$CLJS.ni,$CLJS.V($CLJS.hj,KM),$CLJS.tj,$CLJS.V($CLJS.hj,KM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:bM}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.kd(Ska,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZP,"metabase/mbql/schema.cljc",23,$CLJS.aF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aF,$CLJS.jF,$CLJS.V($CLJS.hj,TP),uM,$CLJS.V($CLJS.hj,TP),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,oP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:ZP}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},
$CLJS.kd(Tja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zM,"metabase/mbql/schema.cljc",21,$CLJS.uF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uF,$CLJS.jF,$CLJS.V($CLJS.hj,TP),uM,$CLJS.V($CLJS.hj,TP),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,oP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:zM}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},$CLJS.kd(fka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LM,"metabase/mbql/schema.cljc",20,$CLJS.iF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iF,$CLJS.jF,$CLJS.V($CLJS.hj,TP),uM,$CLJS.V($CLJS.hj,TP),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,oP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:LM}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==
typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},$CLJS.kd(Jla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pL,"metabase/mbql/schema.cljc",36,$CLJS.bF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.bF,$CLJS.jF,$CLJS.V($CLJS.hj,TP),uM,$CLJS.V($CLJS.hj,TP),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,oP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:pL}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(sla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),
$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",26,$CLJS.RE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.RE,Bja,$CLJS.V($CLJS.hj,KM),Ola,$CLJS.V($CLJS.hj,KM),zla,$CLJS.V($CLJS.hj,KM),Eja,$CLJS.V($CLJS.hj,KM),tla,$CLJS.V($CLJS.hj,KM),Aja,$CLJS.V($CLJS.hj,
KM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:XP}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",28,$CLJS.ME,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,$CLJS.jF,$CLJS.V($CLJS.hj,DM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:sO}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&
"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",29,$CLJS.TE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,$CLJS.jF,$CLJS.V($CLJS.hj,DM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:fP}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",27,$CLJS.KE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,$CLJS.jF,$CLJS.V($CLJS.hj,DM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:RM}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&
"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(ska,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",28,$CLJS.WE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,$CLJS.jF,$CLJS.V($CLJS.hj,DM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:gM}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",33,$CLJS.cB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.cB,$CLJS.jF,$CLJS.V($CLJS.hj,DM),$CLJS.zj,$CLJS.V($CLJS.hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null)],null)),$CLJS.bi,$CLJS.V($CLJS.hj,RN),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:EP}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof nT?new $CLJS.Cc(function(){return nT},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GQ,"metabase/mbql/schema.cljc",27,$CLJS.HE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,Ija,$CLJS.V($CLJS.hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,jla,TL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:GQ}(),nT],null)]))],null)],null));
$CLJS.Y(rP,aL($CLJS.SE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,":case subclause"],null),$CLJS.PS,$R],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$R],null)],null)],null)])));
var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);
$CLJS.Y(YP,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",51,$CLJS.Iq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Iq,$CLJS.yD,$CLJS.V($CLJS.hj,TO),pQ,$CLJS.V($CLJS.hj,TO),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,TO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:DN}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},
$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EN,"metabase/mbql/schema.cljc",51,$CLJS.Zr,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Zr,$CLJS.yD,$CLJS.V($CLJS.hj,OP),
pQ,$CLJS.V($CLJS.hj,TO),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,TO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:EN}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL?new $CLJS.Cc(function(){return pS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,gO,$CLJS.wi,$CLJS.Ni,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.DF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DF,$CLJS.yD,$CLJS.V($CLJS.hj,OP),pQ,$CLJS.V($CLJS.hj,OP),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,OP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CN,"metabase/mbql/schema.cljc",51,$CLJS.Jq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jq,$CLJS.yD,$CLJS.V($CLJS.hj,OP),pQ,$CLJS.V($CLJS.hj,OP),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,OP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:CN}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof bS?new $CLJS.Cc(function(){return bS},
$CLJS.kd(BP,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",58,$CLJS.mF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,yQ,$CLJS.V($CLJS.hj,uL),xQ,$CLJS.V($CLJS.hj,
uL),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,uL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(bS)?bS.H:null])):null));return $CLJS.n(a)?a:QP}(),bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof dS?new $CLJS.Cc(function(){return dS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,
$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.V($CLJS.hj,TP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:iP}(),dS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dO,"metabase/mbql/schema.cljc",55,$CLJS.hF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:dO}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),
$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SL,"metabase/mbql/schema.cljc",54,$CLJS.EE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(sS)?
sS.H:null])):null));return $CLJS.n(a)?a:SL}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",55,$CLJS.oF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:UN}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&
"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",53,$CLJS.JE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.JE,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:wO}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,
cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",69,$CLJS.GE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.GE,$CLJS.yD,$CLJS.V($CLJS.hj,OP),pQ,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:hQ}(),vS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.kd(vka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yN,"metabase/mbql/schema.cljc",68,$CLJS.XE,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:yN}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.kd(yla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),
$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",67,$CLJS.AE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AE,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(xS)?
xS.H:null])):null));return $CLJS.n(a)?a:WN}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.kd(Hja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",67,$CLJS.FF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.yD,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:RQ}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&
"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.kd(FL,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.V($CLJS.hj,XM),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,GO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:nO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.kd(Kja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,
$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XA,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",65,$CLJS.XA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XA,Xja,$CLJS.V($CLJS.hj,bR),Yja,$CLJS.V($CLJS.hj,bR),$CLJS.bi,$CLJS.V($CLJS.hj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:xO}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,
new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",71,$CLJS.bB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.YH,$CLJS.V($CLJS.hj,bR),$CLJS.bi,$CLJS.V($CLJS.hj,Dla),$CLJS.fi,$CLJS.V($CLJS.hj,$CLJS.V(eQ,jR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:QO}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.kd(oka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oM,"metabase/mbql/schema.cljc",71,$CLJS.TH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TH,$CLJS.zO,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:oM}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.oj,!0],
null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",74,$CLJS.iI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.zO,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:OO}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),
$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",72,$CLJS.MH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MH,$CLJS.zO,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:kP}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==
typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",
71,$CLJS.$A,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.zO,$CLJS.V($CLJS.hj,bR),$CLJS.fi,$CLJS.V($CLJS.hj,$CLJS.V(eQ,jR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:jP}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.oj,
!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",70,$CLJS.bI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bI,$CLJS.zO,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:cP}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.kd(Eka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),
$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",78,$CLJS.eI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eI,$CLJS.zO,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:UO}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==
typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.kd(Cka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vL,"metabase/mbql/schema.cljc",
71,$CLJS.gI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,$CLJS.YH,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:vL}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.kd(kla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,
$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",73,$CLJS.dI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dI,$CLJS.YH,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(IS)?IS.H:null])):null));
return $CLJS.n(a)?a:VQ}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bB,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,
[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",73,$CLJS.SH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SH,$CLJS.YH,$CLJS.V($CLJS.hj,bR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:pO}(),JS],null)])));
$CLJS.Y(KL,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof cS?new $CLJS.Cc(function(){return cS},$CLJS.kd(ala,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sP,"metabase/mbql/schema.cljc",59,$CLJS.BE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BE,$CLJS.lx,$CLJS.V($CLJS.hj,TP),$CLJS.vu,$CLJS.V($CLJS.hj,$ja),$CLJS.Pw,$CLJS.V($CLJS.hj,$CLJS.V(eQ,OP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:sP}(),cS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof eS?new $CLJS.Cc(function(){return eS},
$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",54,$CLJS.yF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,$CLJS.lx,$CLJS.V($CLJS.hj,TP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:PP}(),eS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",55,$CLJS.AF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,$CLJS.lx,$CLJS.V($CLJS.hj,TP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:yP}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&
"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",
55,$CLJS.HF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,$CLJS.lx,$CLJS.V($CLJS.hj,TP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:BO}(),fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,
$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",57,$CLJS.GF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GF,$CLJS.lx,$CLJS.V($CLJS.hj,TP),Fja,$CLJS.V($CLJS.hj,$CLJS.nj),$CLJS.iz,$CLJS.V($CLJS.hj,$CLJS.nj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:AQ}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,
new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",55,$CLJS.ps,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.lx,$CLJS.V($CLJS.hj,TP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:eR}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==
typeof fL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.kd(Bla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bQ,"metabase/mbql/schema.cljc",55,$CLJS.js,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.lx,$CLJS.V($CLJS.hj,TP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:bQ}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(Uka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,
$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",56,$CLJS.IE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IE,yQ,$CLJS.V($CLJS.hj,TP),xQ,$CLJS.V($CLJS.hj,TP),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,TP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:pN}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.XO,"null",$CLJS.bj,"null"],
null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mM,"metabase/mbql/schema.cljc",74,$CLJS.lF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lF,$CLJS.lx,$CLJS.V($CLJS.hj,TP),$CLJS.hz,$CLJS.V($CLJS.hj,$CLJS.nj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:mM}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==
typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof bS?new $CLJS.Cc(function(){return bS},$CLJS.kd(BP,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XO,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
QP,"metabase/mbql/schema.cljc",58,$CLJS.mF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,yQ,$CLJS.V($CLJS.hj,uL),xQ,$CLJS.V($CLJS.hj,uL),HM,$CLJS.V($CLJS.hj,$CLJS.V($CLJS.qi,uL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(bS)?bS.H:null])):null));return $CLJS.n(a)?a:QP}(),bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},
$CLJS.kd(FL,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.V($CLJS.hj,XM),
$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,GO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:nO}(),oT],null)])));
$CLJS.pT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,":field or :expression reference or expression",$CLJS.si,function(a){return $CLJS.n($K(PR,a))?cR:$CLJS.n($K(MR,a))?$CLJS.nj:$CLJS.n($K(QR,a))?$CLJS.tr:$CLJS.n($K(SR,a))?$CLJS.YH:$CLJS.n($K($CLJS.SE,a))?$CLJS.SE:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[cR,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,$CLJS.VR],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.JR],null)],null);$CLJS.Y(JQ,aL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.JR],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.Y(yO,aL($CLJS.kF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.JR],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yO],null);$CLJS.Y(IL,aL($CLJS.dF,$CLJS.H(["field-or-expression",$CLJS.pT])));
var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IL],null);$CLJS.Y(BQ,aL($CLJS.EF,$CLJS.H(["field-or-expression",$CLJS.pT])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);$CLJS.Y(hN,aL($CLJS.OE,$CLJS.H(["field-or-expression",$CLJS.pT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null);$CLJS.Y(RP,aL($CLJS.vF,$CLJS.H(["field-or-expression",$CLJS.pT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.Y(cN,aL($CLJS.ni,$CLJS.H(["field-or-expression",$CLJS.pT])));
var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.Y(PM,aL($CLJS.tj,$CLJS.H(["field-or-expression",$CLJS.pT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.Y(JM,aL($CLJS.CF,$CLJS.H(["field-or-expression",$CLJS.pT,"pred",$CLJS.PS])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);$CLJS.Y($M,aL($CLJS.sF,$CLJS.H(["pred",$CLJS.PS])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);$CLJS.Y(IP,aL($CLJS.qF,$CLJS.H(["pred",$CLJS.PS])));
var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);$CLJS.Y(fM,aL($CLJS.xF,$CLJS.H(["field-or-expression",$CLJS.pT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fM],null);$CLJS.Y(hL,aL($CLJS.PE,$CLJS.H(["field-or-expression",$CLJS.pT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hL],null);$CLJS.Y(lN,aL($CLJS.wF,$CLJS.H(["field-or-expression",$CLJS.pT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);$CLJS.Y(ZN,aL($CLJS.VE,$CLJS.H(["field-or-expression",$CLJS.pT,"percentile",YR])));
var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZN],null);$CLJS.Y(LP,aL($CLJS.eF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JJ],null),kR],null)])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);
$CLJS.Y(HP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"unnamed aggregation clause or numeric expression",$CLJS.si,function(a){return $CLJS.n($K(PR,a))?bO:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[bO,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.kd(Zka,
new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kO,"metabase/mbql/schema.cljc",60,$CLJS.dF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:kO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,
new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",64,$CLJS.EF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:oQ}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==
typeof fL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",65,$CLJS.OE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:oO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,
$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tF,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CL,"metabase/mbql/schema.cljc",76,$CLJS.xF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xF,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:CL}(),BT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YL,"metabase/mbql/schema.cljc",60,$CLJS.vF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:YL}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.kd(Cja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,
$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Mr,"metabase/mbql/schema.cljc",60,$CLJS.ni,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ni,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(wT)?wT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Mr}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.kd(xja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Qr,"metabase/mbql/schema.cljc",60,$CLJS.tj,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tj,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Qr}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&
"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.kd(Zja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oN,"metabase/mbql/schema.cljc",18,$CLJS.eF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,lka,$CLJS.V($CLJS.hj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.dr,pka,TL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:oN}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.kd(xka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,
$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gN,"metabase/mbql/schema.cljc",62,$CLJS.qF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,$CLJS.vn,$CLJS.V($CLJS.hj,XN)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:gN}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UL,"metabase/mbql/schema.cljc",68,$CLJS.sF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,$CLJS.vn,$CLJS.V($CLJS.hj,XN)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:UL}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.kd(Ila,new $CLJS.h(null,1,[$CLJS.oj,!0],
null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",66,$CLJS.CF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.CF,cQ,$CLJS.V($CLJS.hj,VP),$CLJS.vn,$CLJS.V($CLJS.hj,XN)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:lO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.kd(FL,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,
new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.V($CLJS.hj,XM),$CLJS.Ii,$CLJS.V($CLJS.hj,$CLJS.V(eQ,GO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:nO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&
"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.kd(nla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.fF,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",
68,$CLJS.wF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wF,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:mQ}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.kd(Jka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,
gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.fF,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",72,$CLJS.VE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.VE,cQ,$CLJS.V($CLJS.hj,VP),$CLJS.VE,$CLJS.V($CLJS.hj,OP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(ET)?ET.H:null])):
null));return $CLJS.n(a)?a:KP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.kd(Nja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xL,"metabase/mbql/schema.cljc",77,$CLJS.PE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,cQ,$CLJS.V($CLJS.hj,VP)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:xL}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,
$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",74,$CLJS.kF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.jF,$CLJS.V($CLJS.hj,$CLJS.V(eQ,DM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:YN}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd(aka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([eO,$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,gQ,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),
$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ei,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.jF,$CLJS.V($CLJS.hj,$CLJS.V(eQ,DM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:$CLJS.ei}(),qT],null)]))],null)],null));
$CLJS.Y($O,aL($CLJS.sL,$CLJS.H(["aggregation",HP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),kR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),kR],null)],null)])));
$CLJS.Y(dQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"aggregation clause or numeric expression",$CLJS.si,function(a){return $CLJS.n($K($CLJS.sL,a))?$CLJS.sL:WM}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$O],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[WM,HP],null)],null));$CLJS.Y(BL,aL($CLJS.gF,$CLJS.H(["field",LR])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BL],null);$CLJS.Y(aO,aL($CLJS.$E,$CLJS.H(["field",LR])));
HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);
$la=bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.kd(rla,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
HN,"metabase/mbql/schema.cljc",15,$CLJS.gF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,$CLJS.jF,$CLJS.V($CLJS.hj,wQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:HN}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.kd(jka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,
$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",16,$CLJS.$E,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.jF,$CLJS.V($CLJS.hj,wQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:CP}(),HT],null)]));
IT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ar,$CLJS.EL,$CLJS.tM,$CLJS.CE,$CLJS.Ps,$CLJS.yj,$CLJS.zO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,kR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lD,kR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.yr,!0],null),kR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,IT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.EL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qL,kR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RO,nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),nR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,IT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.tM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$P,nR],null)],null)],null);
JT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,IT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,JT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.CE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.Ai,$CLJS.ur],
null)],null)],null)],null)],null);$CLJS.KT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.zO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.yj,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null),$CLJS.KT);
$CLJS.Y(vP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.si,$CLJS.Mi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,JT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,ema],null)],null)],null)],null)],null));
var LT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,kR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,kR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,LT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,$CLJS.ur],null)],null)],null),MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null),NT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.si,function(a){return $CLJS.n($CLJS.eD($CLJS.sd,
$CLJS.gP)(a))?$CLJS.gP:$CLJS.kD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,LT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gP,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,MT],null)],null),OT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,kR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bC,lR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,kR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.BN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.sj],null)],null)],null),PT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.gE,null,$CLJS.QD,null,$CLJS.$D,null,$CLJS.dE,null],null),null)),QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);
$CLJS.Y(fN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),PT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NQ,$CLJS.PS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ar,$CLJS.wx,$CLJS.cz],null),QT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),kR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,OT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.eD($CLJS.zz($CLJS.cO,$CLJS.FP),$CLJS.Oe($CLJS.eD($CLJS.cO,$CLJS.FP)))],null)],null));var hma=$CLJS.Q,RT;var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null)],null);
if($CLJS.rd(ST)&&$CLJS.E.g($CLJS.z(ST),$CLJS.Wi)){var TT=$CLJS.y(ST);$CLJS.z(TT);var UT=$CLJS.B(TT),ima=$CLJS.sd($CLJS.z(UT))?UT:$CLJS.ae(null,UT),VT=$CLJS.y(ima),jma=$CLJS.z(VT),kma=$CLJS.B(VT);RT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.R.j(jma,$CLJS.ni,1)],null),kma)}else RT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,ST,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(hM,new $CLJS.P(null,3,5,hma,[$CLJS.kr,RT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"All join aliases must be unique."],null),function(a){return $CLJS.MK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.VD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hM],null);
$CLJS.Y(lQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.h(null,1,[$CLJS.ts,"Distinct, non-empty sequence of Field clauses"],null),cL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),$CLJS.JR],null))],null));
$CLJS.Y(dP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),NT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),PT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),$CLJS.WR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,new $CLJS.h(null,1,
[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),$CLJS.JR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,kR,$CLJS.pT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.PS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hR,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),cL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[hP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[hP,nR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,nR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,OT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Cl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FP,$CLJS.cO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,
new $CLJS.h(null,1,[$CLJS.ts,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.YE);b=$CLJS.J.g(b,$CLJS.RD);return $CLJS.od($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.WT=$CLJS.zg([$CLJS.BF,$CLJS.zO,bN,NM,QN,ZQ,wN,$CLJS.YQ,$CLJS.Ps,vN,LL,SP,OQ,$CLJS.aM,KQ,ZM,$CLJS.VN,tN,$CLJS.Ci,$CLJS.sN,tL,$CLJS.xM,$CLJS.rN,uO,$CLJS.tr,$CLJS.yj,nN],[new $CLJS.h(null,1,[GN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,$CLJS.zO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.yj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,$CLJS.zO,null,$CLJS.YQ,null,$CLJS.VN,null,$CLJS.Ci,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Mi,$CLJS.nj,$CLJS.tD,aQ,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[bN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,cR,$CLJS.tD,aQ,GN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,NM,null,$CLJS.Ps,null,$CLJS.Ci,null,tL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,$CLJS.nj,$CLJS.tD,PL,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[QN,null],null),null)],null),new $CLJS.h(null,1,[GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[ZQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,
cR,$CLJS.tD,hla,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[wN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,cR,GN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,NM,null,$CLJS.Ps,null,$CLJS.Ci,null,tL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,cR,$CLJS.tD,PL,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[vN,null],null),null)],null),new $CLJS.h(null,1,[GN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[LL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,$CLJS.nj,$CLJS.tD,aQ,GN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.BF,null,ZQ,null,LL,null,SP,null,tN,null,$CLJS.Ci,null,tL,null,$CLJS.yj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,$CLJS.nj,$CLJS.tD,PL,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[OQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.YQ,null,$CLJS.aM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Mi,$CLJS.nj,$CLJS.tD,PL,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,$CLJS.nj,$CLJS.tD,PL,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[ZM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.BF,null,$CLJS.zO,null,$CLJS.YQ,null,$CLJS.VN,null,$CLJS.Ci,null],null),null)],null),new $CLJS.h(null,1,[GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[tN,null],null),null)],null),new $CLJS.h(null,1,[GN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Ci,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.YQ,null,$CLJS.sN,null],null),null)],null),new $CLJS.h(null,1,[GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[tL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.YQ,null,$CLJS.xM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.zO,GN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.YQ,
null,$CLJS.rN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,cR,$CLJS.tD,aQ,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[uO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.tr,GN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.BF,null,$CLJS.Ci,null,$CLJS.tr,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.nj,GN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.BF,null,ZQ,null,LL,null,SP,null,tN,null,$CLJS.Ci,null,tL,null,$CLJS.yj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Mi,
cR,$CLJS.tD,PL,GN,new $CLJS.Rg(null,new $CLJS.h(null,1,[nN,null],null),null)],null)]);$CLJS.Y(nQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.WT)));$CLJS.Y(XL,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,1,[$CLJS.ts,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.WT)));
$CLJS.Y(AP,aL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,kR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,kR],null)],null)],null)])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AP],null);$CLJS.Y(wL,aL($CLJS.CE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.JR,XT],null)])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wL],null);$CLJS.Y(rM,aL(qN,$CLJS.H(["target",XT])));
var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.JR,bL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof YT?new $CLJS.Cc(function(){return YT},$CLJS.kd(Hka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,
1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",21,$CLJS.CE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.lI,$CLJS.V($CLJS.hj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,DM,FN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:lP}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=gO.h($CLJS.ld("undefined"!==typeof $CLJS.dL&&
"undefined"!==typeof eL&&"undefined"!==typeof fL&&"undefined"!==typeof ZT?new $CLJS.Cc(function(){return ZT},$CLJS.kd(Wka,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,gO,$CLJS.wi,$CLJS.Ni,$CLJS.Gj,cM,$CLJS.ci,$CLJS.uj,$CLJS.wj],[$CLJS.kd(MP,new $CLJS.h(null,1,[$CLJS.uj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",20,qN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[qN,
$CLJS.lI,$CLJS.V($CLJS.hj,FN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:dR}(),ZT],null)]))],null),XK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dM],null);
$CLJS.Y(dM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.eD($CLJS.zz($CLJS.gP,$CLJS.FE),$CLJS.Oe($CLJS.eD($CLJS.gP,$CLJS.FE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.gP);var d=$CLJS.J.g(c,$CLJS.FE);c=$CLJS.J.g(c,$CLJS.Mi);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$J],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.UJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ar,$CLJS.FE,$CLJS.gP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),MT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.yr,!0],null),kR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lI,new $CLJS.h(null,1,[$CLJS.yr,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),kR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
kR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),xR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[mO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[WL,new $CLJS.h(null,1,[$CLJS.yr,!0],null),oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,mO);b=$CLJS.J.g(b,WL);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.ar,$CLJS.$i,Tka,$CLJS.jN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$P,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,kR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.ur,$CLJS.ur],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.ur,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.ur],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.PN)],null)],null));var $T=$CLJS.WK(XK);(function(){var a=pja();return function(b){if($CLJS.n($T.h?$T.h(b):$T.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.TK(b);throw $CLJS.Uh($CLJS.gD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();